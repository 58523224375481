import { supabase } from "./supabaseClient";
import { User } from "../types/user/user";
import { SocialLinks } from '../types/user/socialLinks';

export async function fetchUserByUsername(userUsername: string): Promise<User | null> {

    const { data: userData, error: userError } = await supabase
        .from("users")
        .select("*")
        .eq("username", userUsername)
        .single();

    if (userError) {
        console.error(`Error fetching user by username: ${userUsername}`, userError);
        return null;
    }

    if (!userData) {
        console.warn(`User with username ${userUsername} not found.`);
        return null;
    }

    const { data: socialLinksData, error: socialLinksError} = await supabase
        .from("social_media")
        .select("*")
        .eq("id", userData.id)
        .single();

    if (socialLinksError) {
        console.error(`Error fetching social links for user ID: ${userData.id}`, socialLinksError);
        return null;
    }

    const socialLinks: SocialLinks = {
        instagram: socialLinksData.instagram || null,
        twitter: socialLinksData.x || null,
        linkedin: socialLinksData.linkedin || null,
        facebook: socialLinksData.facebook || null,
        customLinks: {
            link1: socialLinksData.custom_link.Link1 || null,
            link2: socialLinksData.custom_link.Link2 || null,
            link3: socialLinksData.custom_link.Link3 || null,
            title1: socialLinksData.custom_link.Titre1 || null,
            title2: socialLinksData.custom_link.Titre2 || null,
            title3: socialLinksData.custom_link.Titre3 || null,
        }
    };

    const user: User = {
        id: userData.id,
        firstName: userData.first_name || null,
        lastName: userData.last_name || null,
        username: userData.username || null,
        email: userData.email || null,
        phoneNumber: userData.phone_number || null,
        location: userData.location || null,
        city: userData.city || null,
        profilePictureUrl: userData.picture_profile || null,
        bannerPictureUrl: userData.picture_banner || null,
        qrCodeUrl: userData.qr_code || null,
        job: userData.job || null,
        company: userData.company || null,
        bio: userData.bio || null,
        socialLinks,
    };

    return user;
}

