import React from "react";
import '../styles/components/BannerImage.css';

interface BannerImageProps {
    bannerUrl: string | null | undefined;
    profileUrl: string | null | undefined;
}

const BannerImage: React.FC<BannerImageProps> = ({ bannerUrl, profileUrl }) => {
    if (!bannerUrl || !profileUrl) {
        return null;
    }

    return (
        <div className="banner-container">
            <img src={bannerUrl} alt="Banner" className="banner-image" />
            <img src={profileUrl} alt="Profile" className="profile-image" />
        </div>
    );
};

export default BannerImage;