import React from "react";
import '../styles/components/ContactForm.css';

import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';

interface ContactFormProps {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    phone: string | null | undefined;
}

const ContactForm: React.FC<ContactFormProps> = ({ firstName, lastName, phone }) => {
    const handleClick = () => {
        alert("Nom: ${firstName} ${lastName}, Numéro: ${phone}");
    };

    return (
        <button className="contact-button" onClick={handleClick}>
            <div className="button-row">
                <span className="contact-text">Fiche contact</span>
                <ContactlessOutlinedIcon className="contact-icon" />
            </div>
        </button>
    );
};

export default ContactForm;
