import React from "react";
import { SocialLinks } from '../types/user/socialLinks';
import "../styles/components/SocialLinks.css";

import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import { AiFillTikTok } from "react-icons/ai";

interface SocialLinksProps {
    instagramUrl: string | null | undefined;
    linkedinUrl: string | null | undefined;
    facebookUrl: string | null | undefined;
    twitterUrl: string | null | undefined;
    tiktokUrl: string | null | undefined;
};

const SocialLinksComponent: React.FC<SocialLinksProps> = ({ instagramUrl, linkedinUrl, facebookUrl, twitterUrl, tiktokUrl }) => {
    return (
        <div className="social-links-row">
            {instagramUrl && (
                <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
                    <InstagramIcon className="social-icon" />
                </a>
            )}
            {linkedinUrl && (
                <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon className="social-icon" />
                </a>
            )}
            {facebookUrl && (
                <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
                    <FacebookIcon className="social-icon" />
                </a>
            )}
            {twitterUrl && (
                <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                    <TwitterIcon className="social-icon" />
                </a>
            )}
            {tiktokUrl && (
                <a href={tiktokUrl} target="_blank" rel="noopener noreferrer">
                    <AiFillTikTok className="social-icon" />
                </a>
            )}
        </div>
    );
};

export default SocialLinksComponent;
